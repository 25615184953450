
import { mixins, Options, Vue } from 'vue-class-component';
import { GarmentClient, GenderConfigurationClient } from '@/services/Services';
import * as OM from '@/Model';
import WithModel from '@/mixins/withModel';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class propertiesStep extends mixins(WithModel) {

    @Prop() identifier: string;
    @Prop() index: number;
    @Prop() isLast: boolean;
    declare localValue: OM.GarmentConfigurationPropertyLayers;

    newProperty: string = "";

    addProperty() {
        GarmentClient.addCategoryConfiguration(this.identifier, this.newProperty, this.index)
        .then(x => {
            this.localValue.properties.push(this.newProperty);
            this.newProperty = "";
        })
    }
    removeProperty(propertySelected: string) {
        GarmentClient.removeCategoryConfiguration(this.identifier, propertySelected, this.index)
        .then(x => {
            var indexOf = this.localValue.properties.indexOf(propertySelected);
            if(indexOf > -1)
                this.localValue.properties.splice(indexOf, 1);
        })
    }

}
