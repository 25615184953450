
import { mixins, Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import WithModel from '@/mixins/withModel';
import { GarmentClient } from '@/services/Services';

@Options({})
export default class garmentLayersStep extends mixins(WithModel) {

    declare localValue: OM.GarmentConfiguration;

    editGarmentPropertyLayerVm : OM.EditGarmentPropertyLayerVm = new OM.EditGarmentPropertyLayerVm();

    created(){

    }

    addGarmentPropertyLayer(){
        this.editGarmentPropertyLayerVm.identifier = this.localValue.identifier;

        GarmentClient.addGarmentPropertyLayer(this.editGarmentPropertyLayerVm)
        .then( x => {
            this.editGarmentPropertyLayerVm = new OM.EditGarmentPropertyLayerVm();
            this.$emit("init");
        })
    }

    removeGarmentPropertyLayer(index: number){
        if(confirm("Are you sure you want to delete this layer?")){
            this.editGarmentPropertyLayerVm.identifier = this.localValue.identifier;
            this.editGarmentPropertyLayerVm.index = index;

            GarmentClient.removeGarmentPropertyLayer(this.editGarmentPropertyLayerVm)
            .then( x => {
                this.editGarmentPropertyLayerVm = new OM.EditGarmentPropertyLayerVm();
                this.$emit("init");
            })
        }else{
            return;
        }

    }
}
