
import { mixins, Options, Vue } from 'vue-class-component';
import { CompanyClient, GarmentClient, ProductionProcessClient, ProductionPhaseConfigurationClient } from '@/services/Services';
import * as OM from '@/Model';
import { Prop, Watch } from 'vue-property-decorator';
import WithModel from '@/mixins/withModel';

@Options({})
export default class generalStep extends mixins(WithModel) {

    declare localValue: OM.GarmentConfiguration;

    loaded: boolean = false;
    phasesTextId: OM.TextIdentifier[] = [];
    processes: OM.ProductionProcessConfigurationVM[] = [];
    processesTextId: OM.TextIdentifier[] = [];

    selectedCompany: string = "";

    created(){
        Promise.all([
            ProductionPhaseConfigurationClient.getAllConfigurations()
            .then(x => this.phasesTextId = x.map(c => <OM.TextIdentifier>{
                identifier: c.identifier, 
                text: c.productionPhase 
            })),
            ProductionProcessClient.getAllConfigurations().then(x => this.processes = x),
        ])
        .then(x => {
            this.loaded = true;
        })
    }

    get filteredProcesses(){
        return this.processes
            .filter(x => this.localValue.productionPhases.some(c => c.identifier == x.productionPhase.identifier))
            .map(c => <OM.TextIdentifier>{
                identifier: c.identifier, 
                text: c.name
            })
    }

    getProcessesOf(item: OM.TextIdentifier){
        return this.processes
            .filter(x => x.productionPhase.identifier == item.identifier)
            .map(c => <OM.TextIdentifier>{
                identifier: c.identifier, 
                text: c.name
            })
    }

    @Watch('localValue.productionPhases.length')
    onProductionPhasesLengthChange(next, prev){
        this.localValue.productionProcesses = this.localValue.productionProcesses
            .filter(x => this.localValue.productionPhases.some(c => c.identifier == x.phase.identifier));

        this.localValue.productionPhases.forEach(x => {
            let existing = this.localValue.productionProcesses.some(c => c.phase.identifier == x.identifier);
            if(!existing){
                this.localValue.productionProcesses.push({
                    phase: x,
                    processes: []
                })
            }
        })
    }

}
