import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "list-group mb-5" }
const _hoisted_2 = { class: "fw-bold mb-2" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "d-flex align-items-center" }
const _hoisted_5 = {
  key: 0,
  class: "d-flex justify-content-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.localValue.garmentConfigurationLayerName), 1)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.genders, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "list-group-item d-flex justify-content-between align-items-center pointer",
          key: index,
          onClick: ($event: any) => (_ctx.toggleGender(item))
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: _normalizeClass(["sameAsCheckbox me-2", {'active': _ctx.isSelected(item)}])
            }, null, 2),
            _createTextVNode(" " + _toDisplayString(item), 1)
          ])
        ], 8, _hoisted_3))
      }), 128))
    ]),
    (_ctx.isLast)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("button", {
            class: "btn btn-secondary me-2",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('prev')))
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: "Previous",
              text: "Previous"
            })
          ]),
          _createElementVNode("button", {
            class: "btn btn-tertiary",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('addGendersToGarment')))
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: "Continue",
              text: "Continue"
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}