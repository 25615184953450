
import { Options, Vue } from 'vue-class-component';
import { GarmentClient, GenderConfigurationClient } from '@/services/Services';
import * as OM from '@/Model';
import generalStep from '../components/generalStep.vue';
import garmentLayersStep from '../components/garmentLayersStep.vue';
import gendersStep from '../components/gendersStep.vue';
import propertiesStep from '../components/propertiesStep.vue';
import valuesStep from '../components/valuesStep.vue';

@Options({
    components: {
        generalStep,
        garmentLayersStep,
        gendersStep,
        propertiesStep,
        valuesStep
    }
})
export default class GarmentEdit extends Vue {

    model: OM.GarmentConfiguration = null;
    activeStep: number = 0;

    created() {
        this.init();
    }

    init(){
        let prom: Promise<OM.GarmentConfiguration>;
        if(this.$route.params.identifier == "0") {
            prom = Promise.resolve(new OM.GarmentConfiguration());
        } else {
            prom = GarmentClient.getGarmentDetail(this.$route.params.identifier as string);
        }
        prom.then(x => {
            this.model = x;
        })
    }

    prev() {
        this.activeStep--;
    }
    next() {
        this.activeStep++;
    }

    addGendersToGarment() {
        var proms = [];

        for(var i = 0; i < this.model.garmentConfigurationPropertyLayers.length; i++){
            var vm: OM.SetGenderVM = {
                garmentIdentifier: this.model.identifier,
                genders: this.model.garmentConfigurationPropertyLayers[i].genderValues,
                index: i
            }
            
            proms.push(GarmentClient.setGarmentsGenders(vm));
        }
        
        Promise.all([proms])
        .then(x => {
            this.next();
        })
    }

    saveGarment() {
        if(!this.model.identifier) {
            GarmentClient.createGarment({
                garmentName: this.model.name,
                companies: this.model.companies,
                identifier: null,
                productionPhases: this.model.productionPhases,
                productionProcesses: this.model.productionProcesses,
                hasPadding: this.model.hasPadding
            })
            .then(x => {
                this.model.identifier = x;
                this.$router.replace("/garment/" + x);
                this.next();
            })
        }
        else {
            GarmentClient.updateGarmentGeneral({
                garmentName: this.model.name,
                companies: this.model.companies,
                identifier: this.model.identifier,
                productionPhases: this.model.productionPhases,
                productionProcesses: this.model.productionProcesses,
                hasPadding: this.model.hasPadding
            })
            .then(x => {
                this.next();
            })
        }
    }

}
