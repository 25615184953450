
import { mixins, Options, Vue } from 'vue-class-component';
import { GarmentClient, GenderConfigurationClient } from '@/services/Services';
import * as OM from '@/Model';
import { Prop } from 'vue-property-decorator';
import WithModel from '@/mixins/withModel';
import utils from '@/utils'

@Options({})
export default class valuesStep extends mixins(WithModel) {

    @Prop() identifier: string;
    @Prop() index: number;
    declare localValue: OM.GarmentConfigurationPropertyLayers;

    tempValue: OM.GarmentConfigurationPropertyValue = new OM.GarmentConfigurationPropertyValue();
    vm: OM.PropertyNameInfoVM = new OM.PropertyNameInfoVM();
    modelForValuesList: OM.PropertyNameInfoVM[] = [];
    
    created(){
        this.vm = new OM.PropertyNameInfoVM();
        this.vm.garmentIdentifier = this.identifier;
    }
    
    addValue() {
        this.vm.garmentIdentifier = this.identifier;
        this.vm.value = utils.JsonClone(this.tempValue);
        this.vm.index = this.index;

        GarmentClient.addGarmentConfigurationPropertyValue(this.vm)
        .then(x => {
            this.localValue.propertyValues = x.garmentConfigurationPropertyLayers[this.index].propertyValues;
            this.tempValue = new OM.GarmentConfigurationPropertyValue();

            let propertyName = this.vm.propertyName;
            let gender = this.vm.gender;
            this.vm = new OM.PropertyNameInfoVM();
            this.vm.propertyName = propertyName;
            this.vm.gender = gender;
            this.vm.garmentIdentifier = this.identifier;
        })
    }
    
    removeValue(item: OM.GarmentConfigurationCategoryValues, removeThis: OM.GarmentConfigurationPropertyValue) {
        this.vm.garmentIdentifier = this.identifier;
        this.vm.index = this.index;
        this.vm.gender = item.gender;
        this.vm.propertyName = item.propertyName;
        this.vm.value = utils.JsonClone(removeThis);

        GarmentClient.removeGarmentConfigurationPropertyValue(this.vm)
        .then(x => {
            this.localValue.propertyValues = x.garmentConfigurationPropertyLayers[this.index].propertyValues;
            this.tempValue = new OM.GarmentConfigurationPropertyValue();

            this.vm = new OM.PropertyNameInfoVM();
        })
    }

}
