
import { mixins, Options, Vue } from 'vue-class-component';
import { GarmentClient, GenderConfigurationClient } from '@/services/Services';
import * as OM from '@/Model';
import { Prop } from 'vue-property-decorator';
import WithModel from '@/mixins/withModel';

@Options({})
export default class gendersStep extends mixins(WithModel) {

    @Prop() identifier: string;
    @Prop() index: number;
    @Prop() isLast: boolean;
    declare localValue: OM.GarmentConfigurationPropertyLayers;

    // customGender: string = "";
    genders: string[] = [];

    created(){
        GenderConfigurationClient.getAllGenders()
        .then(x => {
            this.genders = x;
        })
    }

    // addCustomGender() {
    //     GenderConfigurationClient.createCustomGender(this.customGender)
    //     .then(x => {
    //         this.genders.push(this.customGender);
    //         this.localValue.genderValues.push(this.customGender);
    //         this.customGender = "";
    //     })
    // }
    isSelected(gender: string) {
        return this.localValue.genderValues.indexOf(gender) > -1;
    }
    toggleGender(gender: string) {
        var indexOf = this.localValue.genderValues.indexOf(gender);
        if(indexOf > -1)
            this.localValue.genderValues.splice(indexOf, 1);
        else
            this.localValue.genderValues.push(gender);
    }
   
    // remove(item: string){
    //     let indexToRemove = this.genders.indexOf(item);
    //     this.genders.splice(indexToRemove, 1);
    //     indexToRemove = this.localValue.genderValues.indexOf(item);
    //     this.localValue.genderValues.splice(indexToRemove, 1);
    // }

}
